import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import SubscribedThankYou from "../components/SubscribedThankYou"

const SubscribedThankYouPage = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="Thesis | Subscribe confirmation"
      keywords={[]}
      description="You’re still subscribed to the Thesis mailing list."
    />
    <SubscribedThankYou />
  </Layout>
)

export default SubscribedThankYouPage

import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"

const SubscribedThankYou = () => {
  return (
    <div className={styles.container}>
      <Reveal>
        <h2>
          <strong>You are still subscribed.</strong>
        </h2>
        <div className={styles.textContainer}>
          <p>Thanks for the feedback. We&#x27;ll be better for&nbsp;it.</p>
        </div>
      </Reveal>
    </div>
  )
}
export default SubscribedThankYou
